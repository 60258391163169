var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.inProcess
    ? _c(
        "div",
        { staticClass: "full-screen flex flex-center" },
        [
          _c("vue-loading", {
            attrs: {
              type: "spiningDubbles",
              color: "#efad4c",
              size: { width: "25px", height: "25px" },
            },
          }),
        ],
        1
      )
    : _c("cupertino-alert-dialog", {
        attrs: { title: "作品削除の確認", close: _vm.onClickOutSide },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "flex flex-column px-1 py-1" }, [
                  _c("span", [
                    _vm._v(" この作品を削除しますか？ "),
                    _c("br"),
                    _vm._v(" 削除後は"),
                    _c("span", { staticClass: "bold" }, [_vm._v("復元")]),
                    _vm._v("することができません。"),
                    _c("br"),
                    _vm.nolaNovelId
                      ? _c("span", { staticClass: "font-sm" }, [
                          _vm._v(
                            "※連携済みのNolaノベル内のデータは削除されません。"
                          ),
                        ])
                      : _vm._e(),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " 確認のため、下の入力欄に削除する作品のタイトル「 "
                    ),
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ]),
                    _vm._v(" 」を入力してください。 "),
                  ]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.input,
                        expression: "input",
                      },
                    ],
                    staticClass: "flex flex-center py-1/2 px-1/2 mt-1 mb-1/2",
                    style: { resize: "vertical" },
                    attrs: { rows: "3", placeholder: _vm.placeholder },
                    domProps: { value: _vm.input },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.input = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "color-red font-sm" }, [
                    _vm._v(_vm._s(_vm.error.message)),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "flex flex-row flex-center flex-spacearound" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full border-right-1 flex flex-center pointer py-1 hover left-bottom-radius-10",
                        on: { click: _vm.onCancelClick },
                      },
                      [_c("span", [_vm._v("キャンセル")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full flex flex-center pointer py-1 hover right-bottom-radius-10",
                        on: { click: _vm.onDeleteClick },
                      },
                      [
                        _c("span", { staticClass: "color-red" }, [
                          _vm._v("削除する"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }