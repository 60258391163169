var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.close.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dialog flex flex-column line-height-initial" },
        [
          _c("div", { staticClass: "header flex flex-row space-between" }, [
            _c("span", { staticClass: "bold" }, [
              _vm._v("Nolaノベルで作品を公開する"),
            ]),
            _c("span", { staticClass: "pointer", on: { click: _vm.close } }, [
              _vm._v("×ウィンドウを閉じる"),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "flex-1 w-full flex pt-1",
              style: { background: "#D1E1F4" },
            },
            [
              _c("div", {
                staticClass: "align-self-center w-full h-full guide-image",
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "w-full flex flex-column",
              style: { background: "white" },
            },
            [
              _c(
                "h2",
                { style: { paddingLeft: "1.4rem", paddingRight: "1.4rem" } },
                [
                  _vm._v(
                    " Nolaノベルは、作家と編集者がつながる小説投稿サイトです "
                  ),
                ]
              ),
              _c("div", {
                staticClass: "light-gray m-0",
                style: {
                  height: "0.3rem",
                  marginLeft: "1.3rem",
                  marginRight: "1.3rem",
                },
              }),
              _c(
                "span",
                {
                  staticClass: "pt-1 bold line-height-18",
                  style: { paddingLeft: "1.5rem", paddingRight: "1.5rem" },
                },
                [
                  _vm._v(
                    " Nolaノベルは、様々な企業の編集部が探している作品像を知り、編集者に向けて作品投稿できる小説投稿サイトです。 "
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "pb-1 bold line-height-18",
                  style: { paddingLeft: "1.5rem", paddingRight: "1.5rem" },
                },
                [
                  _vm._v(
                    " 自分に合うレーベルを見つけたり、商業デビューに近づくきっかけとしてぜひご利用ください。 "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "px-1 py-1 light-gray radius-8 bold mb-2",
                  style: { marginLeft: "1.3rem", marginRight: "1.3rem" },
                },
                [
                  _vm._v(
                    " Nola内のデータが許諾なく公開されるようなことはございませんので、ご安心いただけますと幸いです。 "
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "light-gray py-1 flex flex-row flex-center" },
            [
              _c(
                "div",
                {
                  staticClass: "btn start flex flex-center pointer",
                  on: {
                    click: function ($event) {
                      return _vm.navigateToNolaNovel()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "bold" }, [
                    _vm._v(" Nolaノベルを利用する "),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }