var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "selectbox",
    },
    [
      _c(
        "div",
        { staticClass: "selected", on: { click: _vm.switchFragment } },
        [
          !_vm.selected
            ? _c("div", { staticClass: "placeholder font-sm" }, [
                _vm._v(_vm._s(_vm.placeholder)),
              ])
            : _c("div", { staticClass: "selected-items font-sm" }, [
                _vm._v(_vm._s(_vm.selectedItems)),
              ]),
          _c("img", {
            staticClass: "icon-sm",
            attrs: { src: require("@/assets/img/icon/select.png") },
          }),
        ]
      ),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSelecting,
              expression: "isSelecting",
            },
          ],
          staticClass: "list",
        },
        _vm._l(_vm.dataItems, function (item) {
          return _c(
            "div",
            { key: item[_vm.keyAttr], staticClass: "selected" },
            [
              _c("select-box-item", {
                staticClass: "list-item",
                attrs: {
                  item: item,
                  label: item.name,
                  icon: item.image,
                  iconPlaceholder: _vm.iconPlaceholder,
                },
                on: { select: _vm.selectItem },
              }),
              _c("check-icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isSelected(item),
                    expression: "isSelected(item)",
                  },
                ],
                staticClass: "check-icon",
              }),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }