var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "select-box-item" },
    [
      _vm.image
        ? _c("ImageView", { staticClass: "image", attrs: { src: _vm.image } })
        : _vm._e(),
      _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }