var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      staticClass: "selectbox",
      class: { disabled: _vm.readonly, error: _vm.error },
      on: { click: _vm.onClickSelectBox },
    },
    [
      _c(
        "div",
        { staticClass: "selected" },
        [
          [
            _vm.text
              ? _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))])
              : _vm.selected
              ? _c("SelectBoxItem", {
                  attrs: {
                    name: _vm.selected.name,
                    image: _vm.selected.image || _vm.placeholderImage,
                  },
                })
              : _c("div", { staticClass: "placeholder" }, [
                  _vm._v(
                    _vm._s(_vm.allowUnselected ? "未選択" : _vm.placeholder)
                  ),
                ]),
          ],
          !_vm.readonly
            ? _c("ChevronDownIcon", {
                staticClass: "caret",
                class: { open: _vm.isOpen },
                attrs: { fillColor: "#669cd9" },
              })
            : _vm._e(),
        ],
        2
      ),
      _vm.isOpen
        ? _c(
            "div",
            { staticClass: "dropdown" },
            [
              _vm.allowUnselected
                ? _c("SelectBoxItem", {
                    key: undefined,
                    staticClass: "item",
                    attrs: { name: "未選択", image: _vm.placeholderImage },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onClickItem(undefined)
                      },
                    },
                  })
                : _vm._e(),
              _vm._l(_vm.options, function (option) {
                return [
                  _c("SelectBoxItem", {
                    key: option.id,
                    staticClass: "item",
                    attrs: {
                      name: option.name,
                      image: option.image || _vm.placeholderImage,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onClickItem(option)
                      },
                    },
                  }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }